<template>
  <div class="box">
    <div class="one">
      <van-field
        readonly
        clickable
        required
        name="picker"
        label="选择学生"
        placeholder=""
        :value="sname"
        input-align="right"
        @click="showPicker1 = true" />
      <van-popup v-model="showPicker1" position="bottom">
        <van-picker
          show-toolbar
          :columns="student_name"
          @confirm="onConfirm"
          @cancel="showPicker1 = false"
          :default-index="2" />
      </van-popup>

      <van-field
        readonly
        clickable
        required
        label="请假类型"
        :value="tname"
        placeholder="病假"
        input-align="right"
        @click="showPicker = true" />

      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns2"
          @cancel="showPicker = false"
          @confirm="onConfirm2" />
      </van-popup>

      <!-- 时间选择器 -->
      <!-- 开始时间 -->
      <van-cell-group class="start-time">
        <van-field
          v-model="time_begin"
          input-align="right"
          required
          label="开始时间"
          placeholder="请选择开始时间"
          @click="startTimeShow = true" />
        <van-popup
          v-model="startTimeShow"
          position="bottom"
          :style="{ height: '35%' }">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="请选择开始日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="startTimeShow = false"
            @confirm="confirmPickerStart" />
        </van-popup>
      </van-cell-group>
      <!-- 结束时间 -->
      <van-cell-group class="end-time">
        <van-field
          input-align="right"
          v-model="time_end"
          required
          label="结束时间"
          placeholder="请选择结束日期"
          @click="endTimeShow = true" />
        <van-popup
          v-model="endTimeShow"
          position="bottom"
          :style="{ height: '35%' }">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="选择结束日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="endTimeShow = false"
            @confirm="confirmPickerEnd" />
        </van-popup>
      </van-cell-group>
    </div>
    <!--  请假理由  -->
    <van-field
      v-model="reasons"
      rows="2"
      autosize
      required
      label="请假理由"
      type="textarea"
      maxlength="300"
      placeholder="请输入请假事由"
      show-word-limit />
    <!-- 上传图片 -->
    <h4>上传图片</h4>
    <van-uploader
      :capture="'camera'"
      v-model="fileList"
      :max-count="3"
      :after-read="afterRead" />
    <van-field
      v-model="teacher_name"
      label="审批人"
      input-align="right"
      required
      is-link />
    <van-button type="info" block @click="handlesend">提交</van-button>
  </div>
</template>

<script>
import { msToDate } from "../../api/timechange";
import { Toast } from "vant";
import {
  Getleavers,
  Cancelleaver,
  geturl,
  daistudentlist,
} from "@/api/leavelist";
export default {
  data() {
    return {
      text: "",
      show: false,
      fieldValue: "",
      cascaderValue: "",
      value: "",  
      reasons: "",
      date: "", //初始化日期为当前日期
      minDate: new Date(2010, 0, 1), //可选择的最小日期
      maxDate: new Date(2025, 1, 1), //可选择的最大日期
      currentDate: new Date(), //选择器默认显示为当前日期
      startTimeShow: false, //开始日期时间选择器显示状态
      endTimeShow: false, //结束日期时间选择器显示状态
      isSelect: false, //开始日期被选中的状态
      Select: false, //结束日期被选中的状态
      time_begin: "", //被选中的开始日期
      time_end: "", //被选中的结束日期
      student_name: [], //学生姓名
      student_ids: [], //学生id
      student_id: "", //学生id
      teacher_name: "", // 审批班主任姓名
      teacher_id: "", //老师id
      showPicker: false,
      showPicker1: false,
      //   columns1: [],
      columns2: [],
      fileList: [],
      leave_type: "",
      type_name: "",
      ids: [],
      file: [],

      // --------
      sname: "",
      sid: "",
      tname: "",
      tid: "",
    };
  },
  created() {
  },
  mounted() {
    this.Gettype();
    this.Getstudent();
    this.Getteacher();
    this.studentList();
  },
  methods: {
    //全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    //开始时间选择器确认按钮触发事件,根据是否被选中的条件渲染当前时间或被选中的开始时间
    confirmPickerStart(val) {
      // console.log(msToDate(val).withoutTime)
      this.time_begin = msToDate(val).hasTime;

      //用户先选择结束时间时取两者的值进行比较判断
      if (new Date(this.time_begin) > new Date(this.time_end)) {
        //开始时间大于结束时间被选中的状态返回false，选中的时间不渲染，datePicker不关闭
        this.isSelect = false;
        Toast({
          message: "结束日期不能在开始日期之前",
          position: "bottom",
        });
      } else {
        //否则返回true，datePicker关闭
        this.isSelect = true;
        this.startTimeShow = false;
      }
    },
    //结束时间选择器确认按钮触发事件,根据是否被选中的条件渲染当前时间或被选中的结束时间
    confirmPickerEnd(val) {
      this.time_end = msToDate(val).hasTime;
      //用户先选择开始时间时取两者的值进行比较判断
      if (new Date(this.time_begin) > new Date(this.time_end)) {
        //console.log(false);
        //开始时间大于结束时间被选中的状态返回false，选中的时间不渲染，datePicker不关闭
        this.Select = false;
        Toast({
          message: "结束日期不能在开始日期之前",
          position: "bottom",
        });
      } else {
        //console.log(true);
        //否则返回true，条件渲染，datePicker关闭
        this.Select = true;
        this.endTimeShow = false;
      }
    },

    //获取请假类型
    async Gettype() {
      await Getleavers("mobile/leave/leaveType", { $limit: 1, $page: 1 }).then(
        (data) => {
          //console.log("得到类型", data.data.data);
          data.data.forEach((i, y) => {
            this.columns2.push(i.type_name);
            this.ids.push(i.id);
          });
        }
      );
    },
    //获取到请假学生/审核老师
    async Getstudent() {
      await Getleavers("mobile/leave/student", { $limit: 1, $page: 1 }).then(
        (data) => {
          // console.log("得到请假学生姓名", data.data.data);
          this.sname = data.data[0].name;
          this.sid = data.data[0].id;
        }
      );
    },
    // 审批人
    async Getteacher() {
      await Getleavers("mobile/leave/teacher", { $limit: 1, $page: 1 }).then(
        (data) => {
          this.teacher_name = data.data[0].name;
          this.teacher_id = data.data[0].id;
        }
      );
    },
    // 请假类型
    onConfirm2(value, index) {
      if (value);
      this.tname = value;
      this.tid = this.ids[index];
      this.showPicker = false;
    },

    //请假学生
    onConfirm(value, index) {
      if (value);
      this.sname = value;
      this.sid = this.student_ids[index];
      this.showPicker1 = false;
    },

    //得到代请假学生列表
    async studentList() {
      await daistudentlist().then((res) => {
        console.log(res);
        res.data.forEach((i) => {
          this.student_name.push(i.name);
          this.student_ids.push(i.id);
        });
      });
    },
    //上传图片
    async afterRead(fileList) {
      var file = fileList.file;
      let f = await this.$api.compressImg(file);
      await geturl("mobile/upload/upload", f).then((res) => {
        this.file.push(res.data.url);
      });
      // console.log(this.file);
      // this.file.push(fileList.file)
    },
    //提交申请
    async handlesend() {
      await Cancelleaver("mobile/leave/add", {
        teacher_id: this.teacher_id,
        student_id: this.sid,
        reasons: this.reasons,
        time_begin: this.time_begin,
        time_end: this.time_end,
        file_type: 1,
        leave_type: this.tid,
        files: this.file,
      }).then((res) => {
        if(res.code == 200){
          history.go(-1)
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  margin: auto;
  margin: 0 0.94rem;
}
.one {
  width: 100%;
  height: 11.88rem;
  background: #ffffff;
  box-shadow: 0rem 0.25rem 0.31rem 0rem rgba(119, 119, 119, 0.08);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  opacity: 1;
  margin: 1.25rem 0;
}
.start-time .end-time {
  display: flex;
  align-items: center;
}
/deep/ .van-cell {
  font-size: 0.88rem;
  color: #333;
  font-weight: 500;
}
/deep/ .van-button--block {
  margin-top: 1.8rem;
}
</style>
