import { render, staticRenderFns } from "./TeaSub.vue?vue&type=template&id=14d5252b&scoped=true&"
import script from "./TeaSub.vue?vue&type=script&lang=js&"
export * from "./TeaSub.vue?vue&type=script&lang=js&"
import style0 from "./TeaSub.vue?vue&type=style&index=0&id=14d5252b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d5252b",
  null
  
)

export default component.exports