
import imgTypeChange from './imgTypeChange';
import request from '@/utils/request'

export const Getleavers = async (url, { $limit = 5, $page = 1}) => {
  const $skip = ($page - 1) * $limit;
  const res = await request.get(`/${url}?$limit=${$limit}&$skip=${$skip}`);
  // console.log("=======",`/${url}?$limit=${$limit}&$skip=${$skip}`)
  return res;
}
export const Getleavers1 = async (url, { $limit = 5, $page = 1 ,status}) => {
  const $skip = ($page - 1) * $limit;
  const res = await request.get(`/${url}?$limit=${$limit}&$skip=${$skip}&status=${status}`);
  // console.log("=======",`/${url}?$limit=${$limit}&$skip=${$skip}`)
  return res;
}
export const Cancelleaver = async (url, data) => {
  const res = await request.post(`/${url}`, { ...data })
  return res
}
export const LeaveDetail = async (url, id) => {
  return await request.get(`/${url}?id=${id}`);
}
export const geturl = async (url, img) => {
  console.log("imgimg",img);
  // console.log("file", img.file);
  if (img.type!=='image/jpeg') {
    console.log('!!!jpg');
    let myfile = await imgTypeChange(img.file)
    console.log("changefile", myfile);
    let formData = new FormData()
    formData.append('image', myfile)
    return await request.post(`${url}`,
    formData
  )
  }
  let formData = new FormData()
  formData.append("image", img)
  return await request.post(`${url}`,
    formData
  )
}
export const daistudentlist = async()=>{
  return await request.get('/mobile/leave/student')
}
export const getClass = async (url) => {
  const res = await request.get(`/${url}`);
  return res;
}
export const getClassList = async (url, limit = 20, page = 1,classes_id) => {
  // const $skip = (page - 1) * limit;
  const res = await request.get(`/${url}?limit=${limit}&page=${page}&classes_id=${classes_id}`);
  return res;
}
// 删除班级圈
export function momentsdel(data) {
  return request({
    url: '/mobile/leave/edit',
    method: 'post',
    data
  })
}